import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Badge from "@mui/material/Badge";
import IconButton from '@mui/material/IconButton';
import { i18n } from "../../translate/i18n";
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReplayIcon from '@mui/icons-material/Replay';
import StopIcon from '@mui/icons-material/Stop';
import api from "../../services/api";
//import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip, Chip } from "@mui/material";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "flex-end",
		marginRight: 32,
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	bottomButton: {
		top: "15px",
	},

	bottomConexao: {
		justifySelf: "flex-end",
		top: "5px",
	},


	bottomBadge: {
		position: "absolute",
		left: "60%",
		bottom: "5px",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},

	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 20,
		bottom: 30,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},

	palette: {
		primary: { main: "#01248e" },
		success: { main: "#4CAF50"},
		warning: { main: "#fb8c00"},
		error: { main: "#f44335"},
		info: { main: "#1A73E8"},
		secondary: { main: "#7b809a"},
		light: { main: "#f0f2f5"},
		dark: { main: "#344767"}
	},
}));

const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	console.log("Lista Tickets: ",ticket);

	useEffect(() => {

		// if (chatLocal === false) {
		let Tks = ticket;

		// if (isGroup === true) {
		// 	Tks = Tks.filter((g) => (g.isGroup === true));
		// } else if (isGroup === false) {
		// 	Tks = Tks.filter((g) => (g.isGroup === false));
		// }

		// const Departamento = Tks.filter((D) => (D.queueIds));



		console.log("Perfil: ",user);
		console.log("Departamento: ",Tks.queueId);
		console.log("Tickets: ",Tks.status);

		// if (profile === "user") {
		// 	dispatch({type: "LOAD_TICKETS", payload: filteredTickets});
		// } else {
		// 	dispatch({type: "LOAD_TICKETS", payload: Tks});
		// }

	}, [ticket, user]);


	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${ticket.id}`);
	};

	const handleReopenTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${ticket.id}`);
	};

	const handleViewTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "pending",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		console.log(id)
		history.push(`/tickets/${ticket.id}`);
	};

	const handleClosedTicket = async id => {
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "closed",
				userId: user?.id,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${ticket.id}`);
	};


	const handleSelectTicket = id => {
		history.push(`/tickets/${id}`);
	};


if(user.profile === "user" && ticket.queueId === null){
	return (
		<>
		<div className={classes.noTicketsDiv}>
		<span className={classes.noTicketsTitle}>
		{i18n.t("ticketsList.noTicketsTitle")}
		</span>
		<p className={classes.noTicketsText}>
		{i18n.t("ticketsList.noTicketsMessage")}
		</p>
		</div>
	</>
	);
} else {

	return (
		<React.Fragment key={ticket.id}>
		<ListItem
	dense
	button
	onClick={e => {
		if (ticket.status === "pending") return;
		handleSelectTicket(ticket.id);
	}}
	selected={ticketId && +ticketId === ticket.id}
	className={clsx(classes.ticket, {
		[classes.pendingTicket]: ticket.status === "pending",
	})}
>
<Tooltip
	arrow
	placement="right"
	title={ticket.queue?.name || "Sem fila"}
		>
		<span
	style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
	className={classes.ticketQueueColor}
		></span>
		</Tooltip>
		<ListItemAvatar>
		<Avatar variant="rounded" src={ticket?.contact?.profilePicUrl} />
	</ListItemAvatar>
	<ListItemText
	disableTypography
	primary={
		<span className={classes.contactNameWrapper}>
		<Typography
	noWrap
	component="span"
	variant="body2"
	color="textPrimary"
		>
		{ticket.contact?.name || "Contato sem nome"}
		</Typography>
	{ticket.lastMessage && (
	<Typography
		className={classes.lastMessageTime}
		component="span"
		variant="body2"
		color="textSecondary"
			>
			{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
		<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
	) : (
	<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
	)}
	</Typography>
	)}
	{ticket.whatsappId && (
	<Tooltip title={i18n.t("ticketsList.connectionTitle")} arrow placement="top">
		<Chip className={classes.bottomConexao} label={ticket.whatsapp?.name} color="primary" size="small"/>
	</Tooltip>
	)}
</span>
}
	secondary={
		<span className={classes.contactNameWrapper}>
		<Typography
	className={classes.contactLastMessage}
	noWrap
	component="span"
	variant="body2"
	color="textSecondary"
		>
		{ticket.lastMessage ? (
				<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
) : (
	<br />
)}
</Typography>

	<Badge
	className={classes.newMessagesCount}
	badgeContent={ticket.unreadMessages}
	classes={{
		badge: classes.badgeStyle,
	}}
	/>
	</span>
}
	/>
	{ticket.status === "pending" && (
	<Tooltip title="Aceitar" arrow placement="bottom-start">
		<IconButton
			className={classes.bottomButton}
			color="success"
			onClick={e => handleAcepptTicket(ticket.id)} >
				<DoneIcon />
		</IconButton>
	</Tooltip>
	)}
	{ticket.status === "pending" && (
	<Tooltip title="Visualizar" arrow placement="bottom-start">
		<IconButton
			className={classes.bottomButton}
			color="primary"
			onClick={e => handleViewTicket(ticket.id)} >
			<VisibilityIcon />
		</IconButton>
	</Tooltip>
	)}
	{ticket.status === "open" && (
	<Tooltip title="Retornar" arrow placement="bottom-start">
		<IconButton
			className={classes.bottomButton}
			color="warning"
			onClick={e => handleViewTicket(ticket.id)} >
				<ReplayIcon />
		</IconButton>
	</Tooltip>
	)}
	{ticket.status === "open" && (
	<Tooltip title="Finalizar" arrow placement="bottom-start">
		<IconButton
			className={classes.bottomButton}
			color="error"
			onClick={e => handleClosedTicket(ticket.id)} >
				<StopIcon />
		</IconButton>
	</Tooltip>
	)}
	{ticket.status === "closed" && (
	<Tooltip title="Reabrir" arrow placement="bottom-start">
		<IconButton
			className={classes.bottomButton}
			color="primary"
			onClick={e => handleReopenTicket(ticket.id)} >
				<ReplayIcon />
		</IconButton>
	</Tooltip>
	)}
	{ticket.status === "closed" && (
	<Tooltip title="Finalizado" arrow placement="bottom-start">
		<Chip className={classes.bottomBadge} label="Finalizado" color="error" size="small" variant="outlined"/>
	</Tooltip>
	)}
</ListItem>
	<Divider variant="inset" component="li" />
		</React.Fragment>
);
	}
};

export default TicketListItem;
