import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { List, Paper, IconButton, Tooltip } from "@mui/material/";
import AddIcon from '@mui/icons-material/Add';
// import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import ChatListItem from "../ChatListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import useTickets from "../../hooks/useTickets";
// import useChatLocal from "../../hooks/useChatLocal";
import { i18n } from "../../translate/i18n";
// import { ListSubheader } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

import NewChatModal from "../NewChatModal";

const useStyles = makeStyles((theme) => ({
  ticketsListWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  ticketsList: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    borderTop: "2px solid rgba(0, 0, 0, 0.12)",
  },

  ticketsListHeader: {
    color: "rgb(67, 83, 105)",
    zIndex: 2,
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ticketsCount: {
    fontWeight: "normal",
    color: "rgb(104, 121, 146)",
    marginLeft: "8px",
    fontSize: "14px",
  },

  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },

  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS") {
    const newTickets = action.payload;


    newTickets.forEach((ticket) => {
      const ticketIndex = state.findIndex((t) => t.id === ticket.id);
      if (ticketIndex !== -1) {
        state[ticketIndex] = ticket;
        if (ticket.unreadMessages > 0) {
          state.unshift(state.splice(ticketIndex, 1)[0]);
        }
      } else {
        state.push(ticket);
      }
    });

    return [...state];
  }

  if (action.type === "RESET_UNREAD") {
    const ticketId = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state[ticketIndex].unreadMessages = 0;
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
    const ticket = action.payload;

    const ticketIndex = state.findIndex((t) => t.id === ticket.id);
    if (ticketIndex !== -1) {
      state[ticketIndex] = ticket;
      state.unshift(state.splice(ticketIndex, 1)[0]);
    } else {
      state.unshift(ticket);
    }

    return [...state];
  }

  if (action.type === "UPDATE_TICKET_CONTACT") {
    const contact = action.payload;
    const ticketIndex = state.findIndex((t) => t.contactId === contact.id);
    if (ticketIndex !== -1) {
      state[ticketIndex].contact = contact;
    }
    return [...state];
  }

  if (action.type === "DELETE_TICKET") {
    const ticketId = action.payload;
    const ticketIndex = state.findIndex((t) => t.id === ticketId);
    if (ticketIndex !== -1) {
      state.splice(ticketIndex, 1);
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsList = (props) => {
  const {
    status,
    searchParam,
    showAll,
    isGroup,
    chatLocal,
    selectedQueueIds,
    updateCount,
    tags,
  } = props;
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(1);
  const [ticketsList, dispatch] = useReducer(reducer, []);
  const [newChatModalOpen, setNewChatModalOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const { profile, queues } = user;
  const history = useHistory();

  // console.log("Style: ",style)

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

  }, [status, searchParam, dispatch, showAll, selectedQueueIds, tags]);

  const { tickets, hasMore, loading } = useTickets({
    pageNumber,
    searchParam,
    status,
    showAll,
    isGroup,
    tags: JSON.stringify(tags),
    queueIds: JSON.stringify(selectedQueueIds),
  });
  //
  // const { chats } = useChatLocal({
  //   pageNumber,
  //   searchParam,
  // });


    useEffect(() => {

      // if (chatLocal === false) {

        const queueIds = queues.map((q) => q.id);

        let Tks = tickets;

        if (isGroup === true) {
          Tks = Tks.filter((g) => (g.isGroup === true));
        } else if (isGroup === false) {
          Tks = Tks.filter((g) => (g.isGroup === false));
        }

        const Departamento = Tks.filter((D) => (D.queueIds));




        const filteredTickets = Tks.filter((t) => queueIds.indexOf(t.queueId) > -1);

      console.log("Perfil: ",profile);
      console.log("Departamento: ",Departamento);
      console.log("Filtro: ",filteredTickets);
      console.log("Tickets: ",Tks);

        if (profile === "user") {
          dispatch({type: "LOAD_TICKETS", payload: filteredTickets});
        } else {
          dispatch({type: "LOAD_TICKETS", payload: Tks});
        }


      // } else {
      //
      //     const Tks = chats;
      //
      //     console.log("Busca: ", chats);
      //
      //
      //     dispatch({type: "LOAD_TICKETS", payload: Tks});
      //
      // }

    }, [tickets, status, searchParam, queues, profile, isGroup]);





  useEffect(() => {
    const socket = openSocket();


    const shouldUpdateTicket = (ticket) =>
      (!ticket.userId || ticket.userId === user?.id || showAll) &&
      (!ticket.queueId || selectedQueueIds.indexOf(ticket.queueId) > -1);

    const notBelongsToUserQueues = (ticket) =>
      ticket.queueId && selectedQueueIds.indexOf(ticket.queueId) === -1;


    socket.on("connect", () => {
      // console.log(status);
      if (status) {
        socket.emit("joinTickets", status);
      } else {
        socket.emit("joinNotification");
      }
    });

    socket.on("ticket", (data) => {

      console.log("Atualiza: ", data);

      if (data.action === "updateUnread") {
        dispatch({
          type: "RESET_UNREAD",
          payload: data.ticketId,
        });
      }

      if (data.action === "update" && shouldUpdateTicket(data.ticket)) {
        dispatch({
          type: "UPDATE_TICKET",
          payload: data.ticket,
        });
      }

      if (data.action === "update" && notBelongsToUserQueues(data.ticket)) {
        dispatch({ type: "DELETE_TICKET", payload: data.ticket.id });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET", payload: data.ticketId });
      }
    });

    socket.on("appMessage", (data) => {


      let Tks = data.ticket;

      // console.log("CREATE:", Tks);

      // if(isGroup === Tks.isGroup) {

        if (data.action === "create" && shouldUpdateTicket(data.ticket)) {
          dispatch({
            type: "UPDATE_TICKET_UNREAD_MESSAGES",
            payload: Tks,
          });
        }

      // }

    });

    socket.on("contact", (data) => {

      if (data.action === "update") {
        dispatch({
          type: "UPDATE_TICKET_CONTACT",
          payload: data.contact,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [status, showAll, user, selectedQueueIds, isGroup]);

  useEffect(() => {
    if (typeof updateCount === "function") {
      // console.log("TKS: ",ticketsList)

      updateCount(ticketsList.length);
    }
    // console.log("TKS: ",ticketsList)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketsList]);

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;

    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const handleCloseOrOpenChat = (ticket) => {
    setNewChatModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }
  // console.log("ISGROUP:", ticketsList);

  return (
      <div className={classes.ticketsListWrapper}>
        <Paper
          square
          name="closed"
          elevation={0}
          className={classes.ticketsList}
          onScroll={handleScroll}
        >
          <NewChatModal
            modalOpen={newChatModalOpen}
            onClose={(ticket) => {
              handleCloseOrOpenChat(ticket);
            }}
          />
        <List style={{ paddingTop: 0 }}>
        {chatLocal === false? (
            <>
          {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noTicketsTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noTicketsMessage")}
              </p>
            </div>
          ) : (
          <>
            {ticketsList.map(ticket => (
              <TicketListItem ticket={ticket} key={ticket.id} />
            ))}
          </>
          )}
          </>
        ) : (
            <>
        {ticketsList.length === 0 && !loading ? (
            <div className={classes.noTicketsDiv}>
              <span className={classes.noTicketsTitle}>
                {i18n.t("ticketsList.noChatTitle")}
              </span>
              <p className={classes.noTicketsText}>
                {i18n.t("ticketsList.noChatMessage")}
              </p>
              <Tooltip
                arrow
                placement="top"
                title="Abrir novo Chat"
                onClick={() => setNewChatModalOpen(true)}
              >
                <IconButton aria-label="Abrir novo Chat">
                  <AddIcon color="success"/>
                </IconButton>
              </Tooltip>
            </div>
        ) : (
          <>
            {ticketsList.map(ticket => (
              <ChatListItem ticket={ticket} key={ticket.id} />
            ))}
          </>
        )}
            </>
        )}
          {loading && <TicketsListSkeleton />}
        </List>
      </Paper>
  </div>
  );
};

export default TicketsList;
